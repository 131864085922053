<template>
<div class="taskboxcontainer"  v-if="showboxcontainer">
    <div class="closebutton" @click="closebox"><close class="closesvg"/></div>
    <div v-show="action==='edittask'||action==='edittaskfromgoaltree'"  class="edittask">
       <div class="edit_task_area" id="input_task_area">
      <div class="task_container addchild_container">
        <a-checkbox  v-model:checked="taskchecked" class="checkbox">
        </a-checkbox>
        <input class="edit_text"   v-model="editcontent"    type="text"  placeholder="please input your task" >
      
      </div>
    
       <div class="edit_task_button_area" id="edit_task_button_area" v-if="editfinishedstatus">
           
               <div class="reviewandbuttonzone">
                <div  v-show="true"  class="edit_review_area" id="add_tasktags_area">
                 <a-select  
                    v-model:value="review_value"
                    mode="multiple"
                    style="width: 100%"
                    placeholder="点击评价你如何完成了这次任务..."
                    :max-tag-count="maxTagCount"
                    :options="options"
                    @change="handleChange"
                    >
                    <template #dropdownRender="{ menuNode: menu }">
                    <v-nodes :vnodes="menu" />
                    <a-divider style="margin: 4px 0" />
                    <div
                    style="padding: 4px 8px; cursor: pointer"
                    @mousedown="e => e.preventDefault()"
                    @click="addfactor"
                    >
                    <plus-outlined />
                    定制评价算法因子
                  </div>
                  </template>
                  </a-select>
                </div>
              
              </div>
              <div class="toolbarzone">
                <div class="devotedtimearea" title="滑动选择完成该任务投入的时间(minute)"><a-slider  v-model:value="devotedtime_from_slider" :max=1000 :disabled="disabled" /></div>
                <div class="select_area" title="点击选择完成日期,默认为今天">
              <timericon  class="timericon" @click="this.showdatepicker=!this.showdatepicker"/>
               
                 </div>
            
                 </div>
                 <a-date-picker   :locale="zhCN"  style="width:40%"    v-if="this.showdatepicker" class="selectcomp"  @change="onChangetimer"     format = "YYMMDD" :bordered ="false"  placeholder="点击选择任务完成日期" />
    
       </div>

       <div class="editdoingindexzone" v-else>
        <SmartIndex
      :difficultyValue="difficultyValue"
      :moodValue="moodValue"
      @update:difficultyValue="updateDifficultyValue"
      @update:moodValue="updateMoodValue"
      v-if="isVipUser"
    />
       </div>
      

       <div>
     
           
    <!-- <a-button :class="['edittaskbutton', dynamicClass]"  @click="updatetask(parenttask)">{{ buttoninfo }}</a-button>  -->
    <a-button 
    :class="['edittaskbutton', dynamicClass]" 
    @click="updatetask(parenttask)" 
    :loading="isLoading" 
    :disabled="isLoading"
  >
    <template v-if="!isLoading">
      {{ buttoninfo }}
    </template>
    <template v-else>
      <!-- <a-spin /> 显示加载图标 -->
    </template>
  </a-button>
       </div>
      </div>


    </div>  
      <!-- <v-select :options="options" label="country" :reduce="country => country.code" /> -->
    <div  v-show="action==='addchildtask'"  class="addchildtask">
        <div class="add_task_area" id="add_task" >
          <div class="input_task_area" id="input_task_area">

            <div class="task_container">
             
            <input class="input_text" v-model="taskcontent"  type="text"  placeholder="请输入你的任务" >
            </div>



            <div class="task_property_area">
              <div class="calendar_project_area">
                <!-- <div class="calendar_area" id="calendar_area">
                  <button id="calendar" class="clock_button">
                <svg t="1598153562536" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"  p-id="3498" width="20" height="20"><path d="M106.666667 810.666667V298.666667h810.666666v512c0 46.933333-38.4 85.333333-85.333333 85.333333H192c-46.933333 0-85.333333-38.4-85.333333-85.333333z" fill="#CFD8DC" p-id="3499"></path><path d="M917.333333 213.333333v128H106.666667v-128c0-46.933333 38.4-85.333333 85.333333-85.333333h640c46.933333 0 85.333333 38.4 85.333333 85.333333z" fill="#F44336" p-id="3500"></path><path d="M704 213.333333m-64 0a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z" fill="#B71C1C" p-id="3501"></path><path d="M320 213.333333m-64 0a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z" fill="#B71C1C" p-id="3502"></path><path d="M704 64c-23.466667 0-42.666667 19.2-42.666667 42.666667v106.666666c0 23.466667 19.2 42.666667 42.666667 42.666667s42.666667-19.2 42.666667-42.666667V106.666667c0-23.466667-19.2-42.666667-42.666667-42.666667zM320 64c-23.466667 0-42.666667 19.2-42.666667 42.666667v106.666666c0 23.466667 19.2 42.666667 42.666667 42.666667s42.666667-19.2 42.666667-42.666667V106.666667c0-23.466667-19.2-42.666667-42.666667-42.666667z" fill="#B0BEC5" p-id="3503"></path><path d="M277.333333 426.666667h85.333334v85.333333h-85.333334zM405.333333 426.666667h85.333334v85.333333h-85.333334zM533.333333 426.666667h85.333334v85.333333h-85.333334zM661.333333 426.666667h85.333334v85.333333h-85.333334zM277.333333 554.666667h85.333334v85.333333h-85.333334zM405.333333 554.666667h85.333334v85.333333h-85.333334zM533.333333 554.666667h85.333334v85.333333h-85.333334zM661.333333 554.666667h85.333334v85.333333h-85.333334zM277.333333 682.666667h85.333334v85.333333h-85.333334zM405.333333 682.666667h85.333334v85.333333h-85.333334zM533.333333 682.666667h85.333334v85.333333h-85.333334zM661.333333 682.666667h85.333334v85.333333h-85.333334z" fill="#90A4AE" p-id="3504"></path></svg>

                  </button>
                </div> -->

                <div id="goal_list" class="goal_list">
                </div>
            </div>

              <div id="tags_review_area" class="tags_review_area" @click="clicktag">
                <div id="tasktags_area">
                  <button id="tags_button" class="tags_button">
                   <svg t="1602774165074" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2453" width="15" height="15">
                    <title>点击选择任务属性</title>
                    <path d="M344.064 194.56c-72.704 0-132.096 59.392-132.096 132.096s59.392 132.096 132.096 132.096c72.704 0 132.096-59.392 132.096-132.096S416.768 194.56 344.064 194.56z m0 185.344c-29.696 0-53.248-23.552-53.248-53.248 0-29.696 23.552-53.248 53.248-53.248 29.696 0 53.248 23.552 53.248 53.248 0 29.696-24.576 53.248-53.248 53.248z m656.384 143.36L512 32.768C496.64 17.408 476.16 9.216 454.656 9.216H79.872C35.84 9.216 0 45.056 0 89.088v345.088c0 21.504 8.192 40.96 23.552 56.32l503.808 501.76c15.36 15.36 35.84 23.552 57.344 23.552 21.504 0 41.984-8.192 56.32-23.552L1000.448 634.88c31.744-30.72 31.744-80.896 0-111.616zM583.68 934.912l-503.808-501.76V89.088h374.784l488.448 490.496L583.68 934.912z" p-id="2454" fill="#d81e06"></path></svg>
                  </button>
                </div>
              </div>
           
            </div>
        
   
            <div class="add_task_button_area" id="add_task_button_area">
                
                <div  v-show="showtagselect"  class="add_tasktags_area" id="add_tasktags_area">
                 <a-select  
                    v-model:value="value"
                    mode="multiple"
                    style="width: 100%"
                    placeholder="选择任务属性..."
                    :max-tag-count="maxTagCount"
                    :options="tasktag_options"
                    @change="handleChange"
                    >
                    <template #dropdownRender="{ menuNode: menu }">
                    <v-nodes :vnodes="menu" />
                    <a-divider style="margin: 4px 0" />
                    <div
                    style="padding: 4px 8px; cursor: pointer"
                    @mousedown="e => e.preventDefault()"
                    @click="addtag"
                    >
                    <plus-outlined />
                    定制任务标签
                  </div>
                  </template>
                  </a-select>

                </div>
                <a-button class="addtask" @click="addchildtask(parenttask)">添加子任务</a-button> 
            </div>

            <SmartIndex
      :difficultyValue="difficultyValue"
      :moodValue="moodValue"
      @update:difficultyValue="updateDifficultyValue"
      @update:moodValue="updateMoodValue"
      v-if="isVipUser"
    />
          </div>
         
        </div>
    </div>

</div>
</template>






<script>

// import Vue from 'vue'
// import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
//  import Multiselect from 'vue-multiselect'
// import DatePicker from 'ant-design-vue/lib/date-picker'; 
import emitter from "../eventbus.js"; 
const axios = require('axios').default;
import { defineComponent,ref,watchEffect } from 'vue';
import configure_options from '../configure'
import { PlusOutlined } from '@ant-design/icons-vue';
import close from '../svg/close.vue'
import timericon  from "../svg/timericon.vue"
// import {  QBadge,QSlider } from 'quasar';
import { computed } from 'vue';
import { useStore } from 'vuex';
import SmartIndex from './smartindex.vue'; 
import zhCN from 'ant-design-vue/es/locale/zh_CN'; // 引入中文语言包
import moment from 'moment';
import 'moment/locale/zh-cn'; // 引入 moment 的中文支持
moment.locale('zh-cn');

export default defineComponent({
     name: 'taskedit',
    props: {
    msg: String,
    parenttask: {
      type: Object,
      required: true
    },
    tab:String,
    action:String,
    source:String,
},
emits:["testchild"],
components:{
  // QSlider,
  // QBadge,
  close,
  timericon,
  PlusOutlined,
  SmartIndex,
  VNodes: (_, {
      attrs,
    }) => {
      return attrs.vnodes;
    },
},
data(){
    return {
        //    value: null,
        // taskcontent,
        edittask:this.parenttask.task,
        tasktag_options: []

    }
},

mounted(){
  // alert(props.parenttask.ID)
  // this.options =this.gettasktags()
  this.getreviewalgo()
  this.gettasktags()
},

methods:{
  onChangetimer(date, dateString) {
      console.log(date, dateString);
      // alert(dateString)
      // alert(date)
      // this.plantime = dateString
      this.finishtime =   dateString
    },




  
  
  clicktag(){
    this.showtagselect = true;
  },
  getreviewalgo(){
    var env =  configure_options["env"]
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]
    
   



    //    //这里是在评价算法那一栏添加对应的tag部分
    //要在axios请求中将这个拼接完成
    var reviewalgo_covert = {
          'attackactively':"主动进攻",
          'makeuseofthings':"利用学习到的东西",
          'depthfirstsearch':"深度优先搜索",
          'markataskimmediately':"立即标记任务",
          'alwaysprofit':"总是保持进步",
          'brainuse':"动脑筋",
          'useprinciple':"使用原则",
          'patience':"有耐心",
          'solveakeyproblem':"解决关键问题",
          'learnnewthings':"学习到新内容",
          'battlewithlowerbrain':"战胜低级情绪",
          'difficultthings':"完成困难事情",
          'acceptfactandseektruth':"接受事实，寻求真相",
          'dealwithahardtask':"处理困难任务",
          'threeminutes':"3分钟原则",
          'serviceforgoal':"为目标服务",
          'buildframeandprinciple':"建立框架原则",
          'atomadifficulttask':"原子化拆分任务",
          'noflinch':"不退缩",
          'learntechuse':"学习技术",
          'doanimportantthingearly':"提前完成一件重要的事",
          'acceptpain':"接受痛苦",
          'conquerthefear':"战胜恐惧",
          'makearecord':"创造新的纪录",
          'life':"生命重要时刻",
          "systematic":"感受到本系统的好处"
    }

    // 尝试将数据转化为options
    // js 循环一个因子
this.reviewalgo_covert = reviewalgo_covert 

var final_options = []
var _this = this
axios.get(serverhost+"/v1/getfactors").then(
        response=>{
          var data = response.data
         console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
         console.log(data);
         this.items = data.factors
         // console.log(status);
         // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
        
         var system_reviewdata =  ['主动进攻', '利用学习到的东西', '深度优先搜索', '立即标记任务', '总是保持进步', '动脑筋', '使用原则', '有耐心', '解决关键问题', '学习到新内容', '战胜低级情绪', '完成困难事情', '接受事实，寻求真相', '处理困难任务', '3分钟原则', '为目标服务', '建立框架原则', '原子化拆分任务', '不退缩', '学习技术', '提前完成一件重要的事', '接受痛苦', '战胜恐惧', '创造新的纪录', '生命重要时刻']

     
      for(var i=0;i<system_reviewdata.length;i++){
        final_options.push({id:i,value:system_reviewdata[i]})
      }

      for(var j=0;j<data.factors.length;j++){
        final_options.push({id:j,value:data.factors[j].Name})
      }
      _this.options =final_options

      return final_options

   
        }
      ).catch(error=>{console.log(error)})



    // return final_options
  },
  gettasktags(){
    //尝试从服务器获取对应的部分 
    var final_options = []

var env =  configure_options["env"]
           
  console.log(configure_options["app_setting"][env])
           var serverhost =configure_options["app_setting"][env]["serverhost"]
    var _this = this
       axios.get(serverhost+"/v1/gettags").then(
        response=>{
          var data = response.data
              console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
         console.log(data);
         _this.items = data.tags
         // console.log(status);
         // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
        
         var taglocaldata =['重要的', '害怕的', '原则', '原子', '需要深度搜索', 
         '困难的', '容易的', '急迫的', '挑战的', '不舒服的', '麻烦事', 
         '关键问题', '10分钟', '30分钟', '3分钟', '人生伟大时刻']
     for(var i=0;i<taglocaldata.length;i++){
        final_options.push({id:i,value:taglocaldata[i]})
      }

      for(var j=0;j<data.tags.length;j++){
        final_options.push({id:j,value:data.tags[j].Name})
      }
      _this.tasktag_options =final_options

      return final_options

   
        }
      ).catch(error=>{console.log(error)})
//  []

  },
  addtag(){
    this.$router.push("/tagmanage")
  }  
  ,
  addfactor(){
    this.$router.push("/factormanage")
  }


},

setup(props,context) {
  const store = useStore();
  const isVipUser = computed(() => {
  return store.getters.userLevel === 'vip';
});
const isLoading = ref(false)
  const value1 = ref(500);
    const handleChange = value => {
      console.log(`selected ${value}`);
      context.emit("testchild")
    };
  const closebox=()=>{
// this.showboxcontainer = false
    emitter.emit("trunofftaskedit","from_taskedit_component")
    // alert("1")
  }
  const taskchecked = ref(false);
  const dynamicClass = ref('pending-task');
  const buttoninfo = ref("提交修改");
 const    editfinishedstatus = ref(false);
  // 动态更新 buttoninfo
  watchEffect(() => {
    buttoninfo.value = taskchecked.value ? '标记完成' : '提交修改';
    editfinishedstatus.value = taskchecked.value ? true : false;
    dynamicClass.value = taskchecked.value ? 'completed-task' : 'pending-task'; // 根据条件改变class
  });
  const difficultyValue = ref(props.parenttask.difficultylevel??2);  // 初始化难度值
    const moodValue = ref(props.parenttask.moodlevel??0);  // 初始化情绪指数
    function updateDifficultyValue(newVal) {
  difficultyValue.value = newVal;
}
function updateMoodValue(newVal) {
  moodValue.value = newVal;
}

function addchildtask(parenttask,){
    // alert(`父亲任务是${parenttask.task}`)
    // alert(this.$store.state.number)
    // alert(this.taskcontent)
    this.showtagselect = false;
    //促进一个状态的改变
    this.$store.commit('modfifyaddchildboxstatus', false)
    // var task =  parenttask
    // const  scheduleids = [task.ID]
      var tagscovert = {
      "importanttag": "重要的",
      'feartag': "害怕的",
      'principletag': "原则",
      'atomtag': "原子",
      'dfstag': "需要深度搜索",
      'hardtag': "困难的",
      'easytag': "容易的",
      'urgenttag': "急迫的",
      'challengetag': "挑战的",
      'uncomfortabletag': "不舒服的",
      'troublesometag': "麻烦事",
      'keyproblemtag': "关键问题",
      'tenminutestasktag': "10分钟",
      'thirtyminutestasktag': "30分钟",
      'threeminutestasktag': "3分钟",
      'lifetag': "人生伟大时刻",
      // 'recordtag': "创造新的纪录",
    }
   

  var tasktags_dict = {
      'importanttag': 'no',
      'feartag': 'no',
      'principletag': 'no',
      'atomtag': 'no',
      'dfstag': 'no',
      'hardtag': 'no',
      'easytag': 'no',
      'urgenttag': 'no',
      'challengetag': 'no',
      'uncomfortabletag': 'no',
      'troublesometag': 'no',
      'keyproblemtag': 'no',
      'tenminutestasktag': 'no',
      'thirtyminutestasktag': 'no',
      'threeminutestasktag': 'no',
      'lifetag': 'no',
      'recordtag':'no'
    }


   var task = {
          'starttime': 'unspecified',
          'deadline': 'unspecified',
          'endtime': 'unspecified',
          'ifdissect': 'no',
          'note': 'unspecified',
          'parentid': parenttask.ID,
          'timedevotedto_a_task': "unspecified",
          'goal': 'read  100 books',
          'parentproject': 'unspecified',
          'inboxlist': 'empty',
          'inbox': this.taskcontent,
          "moodlevel":moodValue.value,
          "difficultylevel":difficultyValue.value,
          'finishtime': 'unspecified',
          'reviewalgolight': 'no',
          'reviewalgo': "unspecified",
          'place': 'unspecified',
          'input-time': 'Fri Aug 21 22:38:23 2020',
          'project': 'unspecified',
          'taskstatus': 'unfinished',
          'plantime': 'today',
          'taglight': 'yes',
          'tasktags': tasktags_dict,
          'client':"dreamforlifeweb",
          'tasktagsorigin': 'e'
        };
        // alert(this.taskcontent)
       if(this.taskcontent==undefined){
        alert("task not allow empty")
        return
       }
       if(this.taskcontent==""){
        alert("task not allow empty")
        return
       }
       var reviewalgo = {
      'attackactively': 'no',
      'makeuseofthings': 'no',
      'depthfirstsearch': 'no',
      'markataskimmediately': 'no',
      'alwaysprofit': 'no',
      'brainuse': 'no',
      'useprinciple': 'no',
      'patience': 'no',
      'solveakeyproblem': 'no',
      'learnnewthings': 'no',
      'battlewithlowerbrain': 'no',
      'difficultthings': 'no',
      'acceptfactandseektruth': 'no',
      'dealwithahardtask': 'no',
      'threeminutes': 'no',
      'serviceforgoal': 'no',
      'buildframeandprinciple': 'no',
      'atomadifficulttask': 'no',
      'noflinch': 'no',
      'learntechuse': 'no',
      'doanimportantthingearly': 'no',
      'acceptpain': 'no',
      'conquerthefear': 'no',
      'life': 'no',
      'makearecord': 'no',
      'systematic':'no'
    }



       //这里是在评价算法那一栏添加对应的tag部分
    var reviewalgo_covert = {
          'attackactively':"主动进攻",
          'makeuseofthings':"利用学习到的东西",
          'depthfirstsearch':"深度优先搜索",
          'markataskimmediately':"立即标记任务",
          'alwaysprofit':"总是保持进步",
          'brainuse':"动脑筋",
          'useprinciple':"使用原则",
          'patience':"有耐心",
          'solveakeyproblem':"解决关键问题",
          'learnnewthings':"学习到新内容",
          'battlewithlowerbrain':"战胜低级情绪",
          'difficultthings':"完成困难事情",
          'acceptfactandseektruth':"接受事实，寻求真相",
          'dealwithahardtask':"处理困难任务",
          'threeminutes':"3分钟原则",
          'serviceforgoal':"为目标服务",
          'buildframeandprinciple':"建立框架原则",
          'atomadifficulttask':"原子化拆分任务",
          'noflinch':"不退缩",
          'learntechuse':"学习技术",
          'doanimportantthingearly':"提前完成一件重要的事",
          'acceptpain':"接受痛苦",
          'conquerthefear':"战胜恐惧",
          'makearecord':"创造新的纪录",
          'life':"生命重要时刻",
          "systematic":"感受到本系统的好处"
    }


  console.log(reviewalgo)
  console.log(reviewalgo_covert)

  
  // 采用循环来提取对应的tag部分
  var task_tags = []
  for(var tag in tagscovert){
    if(this.value.includes(tagscovert[tag])){
      // task_tags.push(tag)
      tasktags_dict[tag]="yes"
    }
  }

  // 直接进行循环测试
  for(var selectedtag in this.value){
    
    tasktags_dict[this.value[selectedtag]]="yes"
  }


  console.log("选中的任务属性在这里")
  console.log(this.value)
  console.log(tasktags_dict)
  console.log(task_tags)

  //  const postbody = {"parentid":parenttask.ID,"inbox":"测试任务"}
   this.$emit('turnoffchildbox', "turnoff")
     var env =  configure_options["env"]
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]

  var _this = this
   axios.post(serverhost + '/v1/createtask', task)
    .then(response => {
        console.log(response.data)
        //可以将状态传入到父亲组件
        // alert(_this.tabinfo)
        if(_this.tabinfo=="todaytab"){
          emitter.emit("refreshtasks","today")
        }else if(_this.tabinfo=="tomorrowtab"){
          emitter.emit("refreshtasks","tomorrow")
        }else{
          emitter.emit("refreshtasks","today")
        }
       
         emitter.emit("refresh_review","from_taskedit_component")
         _this.taskcontent = ""
    })
    .catch(error=>{console.log(error)})
      return 
  }


  function updatetask(task){
    // alert("新修改的任务是")
    var devotedtime = 0
    isLoading.value =  true
    if(this.devotedtime_from_slider>0){
        devotedtime = this.devotedtime_from_slider
    }else{
        console.log("用户未更新")
    }
    
    var selected_goal = "no goal"
    // alert(`修修改的任务是${this.editcontent}，是否checked${this.taskchecked}
    // ，投入的时间为${this.devotedtime_from_slider}
    // `)

    var taskstatus ="unspecified"
    if (this.taskchecked){
      taskstatus="finished"
    }else{
      taskstatus="unfinished"
    }



  var env =  configure_options["env"]
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]
        // 这个需要进行从服务器层面上的构建
     var reviewalgo = {
      'attackactively': 'no',
      'makeuseofthings': 'no',
      'depthfirstsearch': 'no',
      'markataskimmediately': 'no',
      'alwaysprofit': 'no',
      'brainuse': 'no',
      'useprinciple': 'no',
      'patience': 'no',
      'solveakeyproblem': 'no',
      'learnnewthings': 'no',
      'battlewithlowerbrain': 'no',
      'difficultthings': 'no',
      'acceptfactandseektruth': 'no',
      'dealwithahardtask': 'no',
      'threeminutes': 'no',
      'serviceforgoal': 'no',
      'buildframeandprinciple': 'no',
      'atomadifficulttask': 'no',
      'noflinch': 'no',
      'learntechuse': 'no',
      'doanimportantthingearly': 'no',
      'acceptpain': 'no',
      'conquerthefear': 'no',
      'life': 'no',
      'makearecord': 'no',
      'systematic':'no'
    }

     for(var algo_factor in this.reviewalgo_covert){
    if(this.review_value.includes(this.reviewalgo_covert[algo_factor])){
      // task_tags.push(tag)
      reviewalgo[algo_factor]="yes"
    }
  }


    // 直接进行循环测试
    for(var selectedfactor in this.review_value){
    
      reviewalgo[this.review_value[selectedfactor]]="yes"
  }


// 打印对应的信息在这里
// console.log("检查为什么算法不工作")
// console.log(this.review_value)
// console.log( reviewalgo)



       var updatedtask_info  = {
        'ifdissect': 'no',
        'deadline': 'unspecified',
        'starttime': 'unspecified',
        'endtime': 'unspecified',
        'tasktagsorigin': 'unspecified',
        'parentid': 'unspecified',
        'timedevotedto_a_task': devotedtime,
        "goalcode":"xxx",
        'goal': selected_goal,
        'client': 'dreamforlifeweb',
        'taglight': 'no',
        'note': 'unspecified',
        'reviewalgolight': 'yes',
        'reviewalgo': reviewalgo,
        'parentproject': 'unspecified',
        'id': task.ID,
        'place': 'unspecified',
        'finishtime': this.finishtime,
        'inbox': this.editcontent,
        'project': "unspecified",
        'plantime': task.plantime,
        'taskstatus': taskstatus,
        "moodlevel":moodValue.value,
        "difficultylevel":difficultyValue.value,
        'tasktags': {},
        
        }
   var _this = this
   axios.post(serverhost+  '/v1/updatetask', updatedtask_info)
    .then(response => {
      _this.$emit('turnoffchildbox', "turnoff")
        console.log(response.data)
        if(this.action=="edittaskfromgoaltree"){
           //如果是右侧的动作
        // alert("检测")
        emitter.emit("refresh_goaltree","from_taskedit_component")
       }
     
       //左侧更新才掉落
       isLoading.value = false; 
       var svgdata =response.data.svgdata 
       if(updatedtask_info.taskstatus=="finished"){
          emitter.emit("refreshtasksfinished",{type:"finishedtoday",data:svgdata})
        }
        //可以将状态传入到父亲组件
        emitter.emit("refreshtasks","today")
        emitter.emit("refresh_review","from_taskedit_component")

    

    })
    .catch(error=>{console.log(error)
isLoading.value = false

    })
  }
    // alert(props.parenttask.ID)
    return {
      closebox,
      isVipUser,
      showtagselect:ref(false),
      showuserindexzone:ref(true),
      handleChange,
      devotedtime_from_slider:ref(0),
      value1,
      updatetask,
      // difficultyvalue: ref(2),
      addchildtask,
      moodValue,
      difficultyValue,
      updateDifficultyValue,
      updateMoodValue,
      // moodvalue:ref(0),
      buttoninfo,
      editfinishedstatus,
      // difficultyvalue: ref(5),
      editcontent:props.parenttask.task,
      tabinfo:props.tab,
      showdatepicker:ref(false),
      taskcontent:ref(),
      value: ref([]),
   
      finishtime:ref("unspecified"),
      review_value:ref([]),
      maxTagCount:ref(2),
      taskchecked,
      reviewalgo_covert:ref({}),
      showboxcontainer:ref(true),
      dynamicClass,
      isLoading, 
      zhCN,
    };
  },

});


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

.timericon{
  margin-top:3px;
}

.toolbarzone{
  display:flex;
  flex-direction: row;
  .devotedtimearea{
    grid-area: devotedtimearea;
    width: 90%;
  }
}


input.input_text,.edit_text{
  border: none;
  width:100%;

  height: 1.3cm;
  border-bottom : 2px solid rgb(241,243,244);;
  transition: border-bottom 2s linear;


}




.edit_text:focus{
outline: none;
border-bottom: solid 1px red;

}

input.input_text:focus{
  outline: none;

  border-bottom: solid 2px blue;
 
}

.input_task_area,.edit_task_area{
    /* max-width: 30em; */
    /* width: 700px; */
    width: 100%;
  
    /* height: 4em; */
    /* margin-left: auto; */
    /* margin-right: auto; */
    margin-top: 0.2em;
    border: solid 0.1em #ccc;
    border-radius: 0.5em;
}

.tags_button{
  background: none;
  border:none
}

#back{
  position: absolute;;
  left: 10%;
}
#reference{
  font-family: fangsong;
  text-align: left;
  position:absolute;
  left: 40%;
}

.add_task_button_area {  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  /* grid-template-rows: 1fr 1fr 1fr 1fr; */
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "tagzone tagzone tagzone tagzone addtask";
}



.edit_task_button_area {  
  
  
  display: flex;
  /* grid-template-rows: 1fr 1fr 1fr 1fr; */
  gap: 0px 0px;
  flex-direction: column;



    .reviewandbuttonzone{
      grid:reviewandbuttonzone;
      display: flex;
      flex-direction: row;
      // justify-content: space-evenly;
    }

  .edit_review_area{
   width:80%;
  }
  .edittaskbutton{
  
    border-radius:10px;
    // right:1%;
  color:white;
  font-weight: 900;
  border:solid 1px;
  // margin-left:100px;
  background: #1a73e8;
  max-width:200px;
  &:hover{
    background: #4479be;
  }
  }

  .devotedtimearea{
    grid-area: devotedtimearea;
  }

  }



.addtask { 
  grid-area: addtask; 
  border-radius:10px;
  color:white;
  font-weight: 900;
  border:solid 1px;
  background: #1a73e8;
  max-width:100px;
  &:hover{
    background: #4479be;
  }
}

.add_tasktags_area { grid-area: tagzone; }



.closebutton{
  text-align: right;
  // margin-right:10px;
  background: rgb(241,243,244);
  .closesvg{
    margin-right:10px;
  }
}


.taskboxcontainer{
  border: solid 1px black;
  background: white;;
}


.checkbox{
  position: relative;
  top:1em;
}

.addchild_container{
  display: flex;
  flex-direction: row;
  justify-content:center;
}

// .difficultyzone{
//   display: flex;
//   flex-direction: column;
// }

.custom-badge {
 

}
.completed-task{
  color:white;
  font-weight: 900;
  border:solid 1px;
  background: red;
  max-width:100px;
  &:hover{
    background: #f07383;
  }
}

.pending-task{
  color:white;
  font-weight: 900;
  border:solid 1px;
  background: #1a73e8;
  max-width:100px;
  &:hover{
    background: #4479be;
  }
}



</style>